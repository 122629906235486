var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { cols: "6" } }, [
            _c("p", { staticClass: "clearfix mb-0" }, [
              _c(
                "span",
                {
                  staticClass: "float-md-left d-block d-md-inline-block mt-25"
                },
                [
                  _vm._v(
                    " COPYRIGHT © " + _vm._s(new Date().getFullYear()) + " "
                  ),
                  _c(
                    "b-link",
                    {
                      staticClass: "ml-25",
                      attrs: { href: "https://eyetrax.net/", target: "_blank" }
                    },
                    [_vm._v("Eye Trax Inc.")]
                  ),
                  _c("span", { staticClass: "d-none d-sm-inline-block ml-2" }, [
                    _vm._v("Tel: ")
                  ]),
                  _c("a", { attrs: { href: "tel:800.594.4157" } }, [
                    _vm._v("800.594.4157")
                  ]),
                  _c("span", { staticClass: "d-none d-sm-inline-block ml-2" }, [
                    _vm._v("All rights Reserved")
                  ])
                ],
                1
              )
            ])
          ]),
          _c("b-col", { attrs: { cols: "6" } }, [
            _c("p", { staticClass: "clearfix mb-0" }, [
              _c(
                "span",
                {
                  staticClass: "float-md-right d-block d-md-inline-block mt-25"
                },
                [
                  _c("span", { staticClass: "d-none d-sm-inline-block" }, [
                    _vm._v("v" + _vm._s(_vm.appVersion))
                  ])
                ]
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "updatemodal",
          attrs: {
            id: "updatemodal",
            "ok-title": "Update",
            "cancel-variant": "outline-secondary",
            "cancel-title": "Close",
            centered: "",
            size: "lg",
            title: "Update Available"
          },
          on: {
            close: function($event) {
              _vm.showTPModal = false
            }
          }
        },
        [_vm._v(" An update is available. Do you wish to refresh now? ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }