import { render, staticRenderFns } from "./VerticalNavMenuGroup.vue?vue&type=template&id=6b3d80e6&"
import script from "./VerticalNavMenuGroup.vue?vue&type=script&lang=js&"
export * from "./VerticalNavMenuGroup.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/jasontaylor/Sites/Cancom_Secure_Portal_2021/cancom-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6b3d80e6')) {
      api.createRecord('6b3d80e6', component.options)
    } else {
      api.reload('6b3d80e6', component.options)
    }
    module.hot.accept("./VerticalNavMenuGroup.vue?vue&type=template&id=6b3d80e6&", function () {
      api.rerender('6b3d80e6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/@core/layouts/layout-vertical/components/vertical-nav-menu/components/vertical-nav-menu-group/VerticalNavMenuGroup.vue"
export default component.exports