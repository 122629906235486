var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "customizer d-none d-md-block",
      class: { open: _vm.isCustomizerOpen }
    },
    [
      _c(
        "div",
        {
          staticClass:
            "customizer-section d-flex justify-content-between align-items-center"
        },
        [
          _vm._m(0),
          _c("feather-icon", {
            staticClass: "cursor-pointer",
            attrs: { icon: "XIcon", size: "18" },
            on: {
              click: function($event) {
                _vm.isCustomizerOpen = !_vm.isCustomizerOpen
              }
            }
          })
        ],
        1
      ),
      _c(
        "vue-perfect-scrollbar",
        {
          staticClass: "ps-customizer-area scroll-area",
          attrs: { settings: _vm.perfectScrollbarSettings }
        },
        [
          _c(
            "div",
            { staticClass: "customizer-section" },
            [
              _c(
                "b-form-group",
                { attrs: { label: "Skin" } },
                [
                  _c("b-form-radio-group", {
                    attrs: {
                      id: "skin-radio-group",
                      name: "skin",
                      options: _vm.skinOptions
                    },
                    model: {
                      value: _vm.skin,
                      callback: function($$v) {
                        _vm.skin = $$v
                      },
                      expression: "skin"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "customizer-section" },
            [
              _c(
                "b-form-group",
                { attrs: { label: "Menu Layout" } },
                [
                  _c("b-form-radio-group", {
                    attrs: {
                      name: "layout-type",
                      options: _vm.layoutTypeOptions
                    },
                    model: {
                      value: _vm.layoutType,
                      callback: function($$v) {
                        _vm.layoutType = $$v
                      },
                      expression: "layoutType"
                    }
                  })
                ],
                1
              ),
              _vm.layoutType === "vertical"
                ? _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-between align-items-center mt-2"
                    },
                    [
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v("Menu Collapsed")
                      ]),
                      _c("b-form-checkbox", {
                        staticClass: "mr-0",
                        attrs: {
                          name: "is-vertical-menu-collapsed",
                          switch: "",
                          inline: ""
                        },
                        model: {
                          value: _vm.isVerticalMenuCollapsed,
                          callback: function($$v) {
                            _vm.isVerticalMenuCollapsed = $$v
                          },
                          expression: "isVerticalMenuCollapsed"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-between align-items-center mt-2"
                },
                [
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v("Menu Hidden")
                  ]),
                  _c("b-form-checkbox", {
                    staticClass: "mr-0",
                    attrs: { name: "is-menu-visible", switch: "", inline: "" },
                    model: {
                      value: _vm.isNavMenuHidden,
                      callback: function($$v) {
                        _vm.isNavMenuHidden = $$v
                      },
                      expression: "isNavMenuHidden"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("h4", { staticClass: "text-uppercase mb-0" }, [
        _vm._v(" View Customizer ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }